import * as React from 'react';
import Layout from '../layout';
import { GateMask, GateSep, GateContainer, GateContent, GateTitle, GateText, GateSubtitle, LandingWrapper, Catchline, Sep, LandingMessage, LandingTitle, LandingContent, LandingFormContainer, LandingForm, InputField, InputGroup, ButtonGroup, InputInfoIcon, InputInfo } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors } from '../../variables';

import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import { useHistory } from 'react-router-dom';
import livstickApp from '../../models/livstick';
import { ErrorInfo } from '../message/styled';

import icons from './images/icons@2x.png';
import manifest from '../../manifest';
import { events } from '../../events';
import { eventNames } from 'cluster';




const LandingPage:React.FunctionComponent = () => {
    const intl = useIntl();
    const history = useHistory();
    /* Info Input Number */
    const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);
    const openNumberInfo = () => { setInfoNumberActive(true); }
    const closeNumberInfo = () => { setInfoNumberActive(false); }

    /* Info Input */
    const [InfoActive, setInfoActive] = React.useState<boolean>(false);
    const [presenceId, setpresenceId] = React.useState<boolean>(false);

    const [error, setError] = React.useState(false);
    const openInfo = () => { setInfoActive(true); }
    const closeInfo = () => { setInfoActive(false); }

    const getKey = React.useCallback(e => {
      const {keyCode} = e
      if(keyCode === 13 && livstickApp.code != "NOCODE" || "" || undefined) {        
        goToCode();
      }
    }, [])
    React.useEffect(() => {
      livstickApp.code = "NOCODE";
      livstickApp.postEvent(events.landing);
      window.addEventListener("keydown", getKey);
      return () => {
        window.removeEventListener("keydown", getKey);
      }
    }, [getKey]);
 
    const  goToCode = async () => {
      const _code = await livstickApp.triage();
      if (_code.status == "504" ) return setError(true);
      livstickApp.code = _code.ref;
      if (_code.type) return history.push('/'+livstickApp.code)
      else {
        return history.push('/record');
      }
    }

    /* Gate Active */
    const [GateActive, setGateActive] = React.useState<boolean>(false);
    const openGate = () => {
      livstickApp.postEvent(events.landing_send);
      setGateActive(true);
    }

    return <Layout>
      <LandingWrapper>
        <LandingContent>
          <Catchline dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "lp_txt_title1"})}}></Catchline>
          <LandingMessage><FormattedMessage id="lp_txt_title2" /></LandingMessage>
          <LandingFormContainer>
            <LandingForm>
              <InputGroup>
                <InputField placeholder={intl.formatMessage({id: "lp_txt_code"})} onChange={(e) => livstickApp.code = e.currentTarget.value}></InputField> {/* variable : lp_txt_code */}
                <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>  
                <InputInfo active={InfoActive == true}><FormattedMessage id="lp_txt_info"/></InputInfo>
              </InputGroup>
              { error && <ErrorInfo><FormattedMessage id="lp_txt_invalid_code" /></ErrorInfo>}
              <ButtonGroup>
                <Button color={colors.main} hover="main" onClick={() => goToCode()}><FormattedMessage id="lp_txt_submit"/></Button>
              </ButtonGroup>
            </LandingForm>
            </LandingFormContainer>
        </LandingContent>
      </LandingWrapper>
    </Layout>;
}

export default injectIntl(LandingPage);