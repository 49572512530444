import * as React from 'react';
import { MainWrapper, PictureBackground } from './styled';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent = ({children}) => {
    return <MainWrapper>
        <BrowserView>
        <PictureBackground></PictureBackground>
        </BrowserView>
        <MobileView>
        <PictureBackground></PictureBackground>
        </MobileView>
        {children}
    </MainWrapper>;
}

export default Main;
