export const colors = {
  main: '#BCD755',
  secondary: '#000D42',
  other: '#fff',
  back: 'grey',
  red: '#D40132',
  black: '#000000',
  facebook: '#fff',
  twitter: '#fff',
  instagram: '#fff'
}

export const fonts = {
  font: 'Lato',
  bold: '700',
  semibold: '600',
  regular: '400',
  light: '300'
}

export const align = {
  center: 'center',
  left: 'left',
  right: 'right'
}

export const lang = {
  title_vm: 'Le titre 2'
}