import * as React from 'react';
import Layout from '../layout';
import {FieldsRequired, Catchline, Terms, SubtitleHide, InputDateIcon, MessageMobileTitle, MessageWrapper, MessageResumeVideo, MessageFormContainer, MessageResumeVideoContainer, MessageForm, InputGroup, InputField, Sep, FormInfo, SendType, SendTypeItem, InputFieldSpecial, AdditionalMessage, AdditionalMessageCancel, InputTextarea, InputGroupArea, Checkbox, CheckboxLabel, CheckboxGroup, PlanWrapper, PlanSelect, PlanOption, PlanSelectElement, PlanSelectTitle, ButtonGroup, PreviewMobile, PreviewClose, PreviewTitle, ErrorInfo, PreviewMessage, PreviewVideo, InputInfo, InputInfoIcon, StepNumber, SendSelect, SendSelectElement, SendOption, SendReinsurance} from './styled';
import { Button, ButtonAddMessage, AlignCenter, AlignRight, MobileMask } from '../../widgets/globaleStyledWidget';
import { GateSelect, GateOption } from '../gate/styled';
import { colors } from '../../variables';
import { getYear, getMonth } from 'date-fns';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import { Link, useHistory } from 'react-router-dom';

import icoMail from './images/ico-mail.svg';
import icoPhone from './images/ico-phone.svg';
import icoAdd from './images/ico-add.svg';
import icoClock from './images/ico-clock.svg';
import icoDate from './images/ico-calendar.svg';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import livstickApp, { language } from '../../models/livstick';

import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import manifest from '../../manifest';
import { COUNTRIES, COUNTRIES_DETAILS } from '../../lang/countries';
import { events } from "../../events"
import { Observer } from 'mobx-react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';



const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const MessagePage:React.FunctionComponent = () => {
  const history = useHistory();
  
  const intl = useIntl();

  const videoEl = React.useRef(null);

  const [gateDate, setGateDate] = React.useState<Date>();
  const [plannification, setPlannification] = React.useState<Date>();

  React.useEffect(() => {
    console.log('ref', videoEl)
    var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      console.log('videoFile', livstickApp.videoFile)
      var fileUrl = URL.createObjectURL(livstickApp.videoFile);
      var fileType = livstickApp.videoFile.type;
      _player.src({ type: fileType, src: fileUrl });
      _player.load();
      //_player.play();
      _player.loop();
    });
    
  }, [])

  const [sendTypeIndex, setSendTypeIndex] = React.useState<number>(0);
  const [TextareaActive, setTextareaActive] = React.useState<boolean>(false);
  const [ScheduleActive, setScheduleActive] = React.useState<boolean>(false);
  const [gateCountry, setGateCountry] = React.useState<Number>();


  /* Checkbox Plannification envoi */
  const [isPlanToggled, setPlanToggled] = React.useState<boolean>(false);
  const togglePlanTrueFalse = () => {
    livstickApp.planificationTime = !isPlanToggled;
    setPlanToggled(!isPlanToggled);
  }

  /* Checlbox CGU */
  const [isCGUToggled, setCGUToggled] = React.useState<boolean>(false);
  const toggleCGUTrueFalse = () => setCGUToggled(!isCGUToggled);

   /* Checlbox CGU */
   const [isCGU2Toggled, setCGU2Toggled] = React.useState<boolean>(false);
   const toggleCGU2TrueFalse = () => setCGU2Toggled(!isCGU2Toggled);
  
  /* Preview Mobile / Tablette */
  const [PreviewActive, setPreviewActive] = React.useState<boolean>(false);
  const openPreview = () => { setPreviewActive(true); }
  const closePreview = () => { setPreviewActive(false); }

  /* Info Input */
  const [InfoActive, setInfoActive] = React.useState<boolean>(false);
  const openInfo = () => { setInfoActive(true); }
  const closeInfo = () => { setInfoActive(false); }

  /* Info Input Number */
  const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);
  const openNumberInfo = () => { setInfoNumberActive(true); }
  const closeNumberInfo = () => { setInfoNumberActive(false); }

  const [error, setError] = React.useState(false);
  const [error2, setError2] = React.useState(false);
  const [error3, setError3] = React.useState(false);

  

  const openSendType = (index: number) => {
    setSendTypeIndex(index);
  }

  const openTextarea = () => {
    setTextareaActive(true);
  }

  const closeTextarea = () => {
    setTextareaActive(false);
  }

  const openSchedule = () => {
    setScheduleActive(true);
  }

  const closeSchedule = () => {
    setScheduleActive(false);
  }

  const goToMessage = () => {
    history.push('/record');
  }

  const goToRecord = () => {
    history.push('/record');
  }

  const goToUpload = () => {
    livstickApp.postEvent(events.form_v);
    console.log(livstickApp.senderEmail, isCGUToggled, livstickApp.senderEmail != null && isCGUToggled);
    if (!isCGUToggled) setError2(true); else setError2(false);
    if (!livstickApp.senderEmail) setError(true); else setError(false);
    if (livstickApp.senderEmail != null && isCGUToggled) history.push('/upload');
  }


  const [startDate, setStartDate] = React.useState(new Date());
    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ];

  return <Layout>
    <PreviewMobile active={PreviewActive == false}>
      <PreviewClose onClick={() => closePreview()}></PreviewClose>
      <PreviewTitle><FormattedMessage id="form_txt_title_preview_mobile"/></PreviewTitle>
      <PreviewVideo>
        <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
      </PreviewVideo>
      <Button onClick={() => closePreview()} color={colors.black} hover="black"><FormattedMessage id="form_txt_close_preview_mobile"/></Button>
    </PreviewMobile>
    <Observer>{() => 
    <MessageWrapper>
      <MessageResumeVideoContainer>
        <h2><FormattedMessage id="form_txt_title_form"/></h2>
        <MessageResumeVideo>
          <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
        </MessageResumeVideo>
        {/* ROMAIN : Changement boutton */}<Button color={colors.secondary} hover="secondary" onClick={goToMessage}><FormattedMessage id="player_txt_button_retry"/></Button>
      </MessageResumeVideoContainer>
      
      
      <MessageFormContainer>
        
        <MessageForm>
          {/* ROMAIN : Spécifique HO */}<StepNumber><FormattedMessage id="global_txt_step"/> 2/2</StepNumber>
          {/* ROMAIN : Changement de positionnement HO */}<MessageMobileTitle><h2><FormattedMessage id="form_txt_complete_form"></FormattedMessage></h2></MessageMobileTitle>
          <h3><FormattedMessage id="form_txt_from"/></h3>
          <InputGroup>
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_fname"})}></InputField> {/* variable : form_txt_from_fname */}
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_lname"})}></InputField> {/* variable : form_txt_from_lname */}
          </InputGroup>
          <InputGroup>
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_email"})} onChange={(e) => { livstickApp.senderEmail = e.target.value; }}></InputField> {/* variable : form_txt_from_email */}
            <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>
            <InputInfo active={InfoActive == true}><FormattedMessage id="form_txt_to_reinsurance_email"/></InputInfo>
          </InputGroup> 
          {/* ROMAIN : Pas sur Livstick
          <InputGroup className="">
            <PlanSelectElement>
              <PlanSelectTitle>Pays :</PlanSelectTitle>
              <GateSelect onChange={e => setGateCountry(parseInt(e.currentTarget.value))}>
                <GateOption>--</GateOption>
                {COUNTRIES_DETAILS.map(_d => {
                  var _s = COUNTRIES.find(__s => __s.COU_CODE == _d.COD_CODE && language.codeInt == __s.COU_LANGUAGE );
                  return _s != null ? <GateOption value={_d.COD_ID}>{_s.COU_NAME}</GateOption> : null;
                })}
              </GateSelect>
            </PlanSelectElement>
            <PlanSelectElement>
            <PlanSelectTitle>Date de naissance :</PlanSelectTitle>
            <InputField className="only-mobile" type="date" value="02/10/2020"></InputField>
            <DatePicker locale="fr-FR" dateFormat="dd/MM/yyyy" selected={gateDate} onChange={date => setGateDate(date as Date)} placeholderText={intl.formatMessage({id: "gate_txt_dateofbirth"})}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(parseInt(value))}
                  >
                    {years.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
            </PlanSelectElement>
            
          </InputGroup>
          */}
          {error && <ErrorInfo><FormattedMessage id="form_txt_error1"/></ErrorInfo>}
          {error3 && <ErrorInfo><FormattedMessage id="form_txt_error3"/></ErrorInfo>}
          { livstickApp.isMatch && <>
          <h3><FormattedMessage id="form_txt_to" /></h3>
            <SendSelectElement>
              <SendSelect onChange={e => {
                 if (e.currentTarget.value == "email"){
                   setSendTypeIndex(1)
                 }else if (e.currentTarget.value == "phone"){
                   setSendTypeIndex(0)
                 }else{
                   setSendTypeIndex(2)
                 }
               }}>
                 
                 <SendOption value="phone">{intl.formatMessage({id:'form_txt_to_byphone'})}</SendOption>
                 <SendOption value="email">{intl.formatMessage({id:'form_txt_to_bymail'})}</SendOption>
               </SendSelect>
             { sendTypeIndex == 1 && <InputFieldSpecial placeholder={intl.formatMessage({id: "form_txt_to_email"})} onChange={(e) => { livstickApp.email = e.target.value; }}></InputFieldSpecial> }
             { sendTypeIndex == 0 && <PhoneInput
              country={'tr'}
              preferredCountries={['tr']}
              onChange={phone => livstickApp.phone = phone}
            /> }
           
            
            </SendSelectElement>
            </>}
          {/* <ErrorInfo>Veuillez saisir un ou plusieurs destinataires.</ErrorInfo> */}
          
          {/* ROMAIN : Sur HO, on a un cas particulier, on affiche par défaut directement le message complémentaire */}
          <AdditionalMessage>
            <h3 className="h3-add-message"><FormattedMessage id="form_txt_add_message_title"/></h3>
          </AdditionalMessage>
          <InputGroupArea>
            <InputTextarea placeholder={intl.formatMessage({id: "form_txt_add_message_placeholder"})} onChange={(e) => { livstickApp.message = e.target.value; } }></InputTextarea> {/* variable : form_txt_add_message_placeholder */}
          </InputGroupArea>
          { manifest.VM && <>
          <ButtonAddMessage onClick={() => openSchedule()} active={ScheduleActive == false}><FormattedMessage id="form_txt_schedule" /></ButtonAddMessage>
          <SubtitleHide active={ScheduleActive == true}>
            <h3 className="h3-add-message"><FormattedMessage id="form_txt_schedule"/></h3>
            <AdditionalMessageCancel onClick={() => closeSchedule()}><FormattedMessage id="form_txt_add_message_cancel"/></AdditionalMessageCancel>
          </SubtitleHide>
          <PlanWrapper active={ScheduleActive == true}>
            <PlanSelectElement>
              <PlanSelectTitle><img src={icoDate} /> <FormattedMessage id="form_txt_schedule_date" defaultMessage="Date"/></PlanSelectTitle>
                <InputGroup>
                  <DatePicker locale="fr-FR" dateFormat="dd/MM/yyyy" selected={plannification} onChange={date => {
                    setPlannification(date as Date);
                      livstickApp.planification = date as Date;
                    }
                      
                    }
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <select
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) => changeYear(parseInt(value))}
                        >
                          {years.map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  />
                  <InputDateIcon ></InputDateIcon>  
                </InputGroup>
            </PlanSelectElement>
            <PlanSelectElement className="only-mobile">
              <InputField className="" value="02/10/2020" type="date"></InputField>
            </PlanSelectElement>
            <PlanSelectElement>
              <PlanSelectTitle><img src={icoClock} /> <FormattedMessage id="form_txt_schedule_time" defaultMessage="Heure"/></PlanSelectTitle>
              <PlanSelect>
                <PlanOption>08:00</PlanOption>
                <PlanOption>12:00</PlanOption>
                <PlanOption>16:00</PlanOption>
                <PlanOption>20:00</PlanOption>
              </PlanSelect>
            </PlanSelectElement>
          </PlanWrapper>
          </>}
          

           {/*<Terms dangerouslySetInnerHTML={{__html:intl.formatMessage({id: 'form_txt_terms_mc'})}}></Terms>*/}
          <CheckboxGroup onClick={toggleCGUTrueFalse} active={isCGUToggled == true}>
            <Checkbox/>
            <CheckboxLabel  dangerouslySetInnerHTML={{__html:intl.formatMessage({id: 'form_txt_checkbox1'})}}></CheckboxLabel>
          </CheckboxGroup>
          {/*<CheckboxGroup onClick={toggleCGU2TrueFalse} active={isCGU2Toggled == true}>
            <Checkbox/>
            <CheckboxLabel><FormattedMessage id="form_txt_checkbox2"/></CheckboxLabel>
          </CheckboxGroup>*/}
          {/*
          <ButtonGroup>
             <Button color={colors.secondary} onClick={goToRecord}><FormattedMessage id="player_txt_button_retry"/></Button>
            <Button onClick={() => openPreview()} color={colors.secondary}><FormattedMessage id="form_txt_button_preview"/></Button>
          </ButtonGroup>
          */}
          {error2 && <ErrorInfo><FormattedMessage id="form_txt_error2" defaultMessage="Error 2"/></ErrorInfo>}
          <AlignRight>
            {/* ROMAIN : Bouton changé */}<Button color={colors.main} hover="main" onClick={ goToUpload }><FormattedMessage id="form_txt_send"/></Button>
          </AlignRight>
          <AlignRight>
            <FieldsRequired><FormattedMessage id="form_txt_mandatory"/></FieldsRequired>
          </AlignRight>
        </MessageForm>
      </MessageFormContainer>
    </MessageWrapper>
    }</Observer>
  </Layout>
}

export default injectIntl(MessagePage);